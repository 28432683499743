import React from 'react';
import c from 'classnames';
import { useTranslation } from 'react-i18next';
import LoadableIngressForm from '@wix/da-dreamup/pkg/components/LoadableIngressForm';
import { Aside } from '@wix/da-a11y/pkg/components/headings/tagWrappers';
import Heading from '@wix/da-a11y/pkg/components/headings/Heading';
import s from './DreamupEntryForm.scss';

interface Props {
  className?: string;
}

const DreamupEntryForm: React.FC<Props> = ({ className }) => {
  const { t } = useTranslation();
  return (
    <Aside className={c(s['root'], className)}>
      <Heading className={s['heading']}>
        {t('tagPage.dreamupEntry.heading')}
      </Heading>
      <div className={s['form-wrapper']}>
        <LoadableIngressForm hideArtStyle />
      </div>
    </Aside>
  );
};

DreamupEntryForm.displayName = 'DreamupEntryForm';

export default DreamupEntryForm;
