import { PapiTag } from '@wix/da-papi-types';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Url } from '@wix/da-url';
import { MobileContext } from '@wix/da-react-context/pkg/MobileContext';
import { SessionContext } from '@wix/da-react-context/pkg/publicSession';
import { ResourceType } from '@wix/da-shared-react/pkg/types/resource';
import { BiData, DreamupInfoClickBiEvent } from '@wix/da-bi/pkg/events';
import DreamUpStarsIcon from '@wix/da-ds/pkg/Icons/24x24/DreamUpStars';
import DreamUpStarsIcon16 from '@wix/da-ds/pkg/Icons/16x16/DreamUpStars';
import GhostButton from '@wix/da-ds/pkg/Buttons/GhostButton';
import { useFlag } from '@wix/da-react-context/pkg/flags/hooks';
import EmptyResult from '../../../EmptyResult';
import OrderDropdown from '../../_partials/OrderDropdown';
import BasePageLayout from '../../_partials/BasePageLayout';
import TagSliderSubnav from '../../_partials/TagSliderSubnav';
import DefaultPageContent from '../../_partials/DefaultPageContent';
import { DailyChallengeArray } from './DailyChallenges/types';
import DailyChallengePageHeader from './DailyChallenges/DailyChallengePageHeader';
import SelectedDailyChallengeHeading from './DailyChallenges/SelectedDailyChallengeHeading';
import DailyChallengeTagPage from './DailyChallenges/DailyChallengeTagPage';
import DreamupEntryForm from './DreamupEntryForm';

import s from './TagPage.scss';

export interface Props {
  tag?: PapiTag;
  hasOrderSubnav?: boolean;
  dailyChallenges?: DailyChallengeArray;
}

const TagPage: React.FC<Props> = ({ tag, hasOrderSubnav, dailyChallenges }) => {
  const { t } = useTranslation();
  const isMobile = useContext(MobileContext);
  const isDesktop = !isMobile;
  const isDsUpdate = useFlag('ds_update_btn_medium');
  const daily_challenge_v2 = useFlag('daily_challenge_v2');
  const { aiContentFilter } = useContext(SessionContext);
  const isTopicsDreamupEntryFlagEnabled = useFlag('topics_dreamup_entry');
  const isTagOfficialTopic = tag?.typeId === ResourceType.TOPIC;

  if (!tag) {
    return <EmptyResult />;
  }

  // precondition: backend won't send dailyChallenges unless we've matched a tag in them
  const showDailyChallenges = !!dailyChallenges;
  if (showDailyChallenges) {
    return daily_challenge_v2 ? (
      <DailyChallengeTagPage tag={tag} dailyChallenges={dailyChallenges} />
    ) : (
      <BasePageLayout
        customHeader={
          <DailyChallengePageHeader
            tag={tag.tag}
            dailyChallenges={dailyChallenges}
          />
        }
        withTabHeader={false}
        withNetworkBar={false}
        withNavigation={false}
        contentClassName={s['daily-challeges-content-block']}
      >
        <SelectedDailyChallengeHeading
          className={s['current-challenge-header']}
          tag={tag.tag}
          tagItemId={tag.itemId}
          dailyChallenges={dailyChallenges}
        />

        <DefaultPageContent />
      </BasePageLayout>
    );
  }

  const showDreamUpCTA = aiContentFilter === 'allow';
  const dreamupCTAButtonMedium = (
    <GhostButton
      variant="main"
      size="medium"
      icon={isDsUpdate ? DreamUpStarsIcon16 : DreamUpStarsIcon}
      href={Url.dreamupLink({ prompt: tag.tag })}
      biData={BiData<DreamupInfoClickBiEvent>({
        evid: 923,
        typeid: ResourceType.DISCOVERYTAG,
        itemid: tag.itemId,
      })}
      className={s['dreamup-cta-button']}
    >
      {t('tagPage.headline.dreamupCta.label')}
    </GhostButton>
  );
  const dreamupCTAButtonLarge = (
    <GhostButton
      variant="main"
      size="large"
      icon={DreamUpStarsIcon}
      href={Url.dreamupLink({ prompt: tag.tag })}
      biData={BiData<DreamupInfoClickBiEvent>({
        evid: 923,
        typeid: ResourceType.DISCOVERYTAG,
        itemid: tag.itemId,
      })}
      className={s['dreamup-cta-button']}
    >
      {t('tagPage.headline.dreamupCta.label')}
    </GhostButton>
  );

  const headerLeftSide = isMobile ? (
    <h1 className={s['title']}>{tag.name}</h1>
  ) : (
    <div className={s['headline-with-cta']}>
      <h1 className={s['title']}>
        {t('tagPage.headline', {
          postProcess: ['reactPostprocessor', 'featureBrancher'],
          tag: <span className={s['title-tag']}>{tag.name}</span>,
        })}
      </h1>
      {showDreamUpCTA &&
        (!isTagOfficialTopic || !isTopicsDreamupEntryFlagEnabled) &&
        dreamupCTAButtonMedium}
    </div>
  );

  const tagSliderSubnav = (
    <TagSliderSubnav
      className={s['tag-slider-subnav']}
      withClearButton
      withTodaysTagIcon={daily_challenge_v2}
    />
  );
  return (
    <BasePageLayout
      wrapperClassName={s['root']}
      aboveHeader={isMobile && tagSliderSubnav}
      headerClassName={s['header']}
      innerHeaderClassName={s['inner-header']}
      headerIsStickyOnScrollUp={false}
      headerLeftSideContent={headerLeftSide}
      headerRightSideContent={
        hasOrderSubnav && <OrderDropdown className={s['order-dropdown']} />
      }
      withNetworkBar={false}
      withNavigation={false}
      belowHeader={isDesktop && tagSliderSubnav}
      contentClassName={s['content-block']}
    >
      {isMobile &&
        showDreamUpCTA &&
        (!isTagOfficialTopic || !isTopicsDreamupEntryFlagEnabled) && (
          <div className={s['mobile-cta-wrapper']}>{dreamupCTAButtonLarge}</div>
        )}

      {isTopicsDreamupEntryFlagEnabled &&
        showDreamUpCTA &&
        isTagOfficialTopic && (
          <DreamupEntryForm className={s['dreamup-form']} />
        )}

      <DefaultPageContent />
    </BasePageLayout>
  );
};
TagPage.displayName = 'TagPage';

export default TagPage;
